export const URL_BASE_CONFIG = 'https://region-configuration.lalamove.com';
export enum ENV_APP {
  'PRODUCTION' = 'PRODUCTION',
  'SANDBOX' = 'SANDBOX',
}
export enum ENV_DEVELOP {
  DEV = 'DEV',
  STG = 'STG',
  PRE = 'PRE',
  PRODUCTION = 'PRODUCTION',
}
export enum VERIFICATION_CODE {
  SMS = 'SMS',
  VOICE_CAL = 'VOICE_CAL',
}
export enum ContactWay {
  PHONE = 'phone',
  EMAIL = 'email',
}
export enum COUNTRY {
  CHINA = 1000,
  INDIA = 10000,
  BRAZIL = 20000,
  SINGAPORE = 30000,
  MALASIA = 40000,
  PHILIPPINES = 50000,
  MEXICO = 60000,
  // USA = 70000,
  TAIWAN = 80000,
  HONGKONG = 90000,
  VIETNAM = 100000,
  THAILAND = 110000,
  INDONESIA = 120000,
}
export const COUNTRY_Map = {
  1000: 'China',
  10000: 'India',
  20000: 'Brazil',
  30000: 'Singapore',
  40000: 'Malaysia',
  50000: 'Philippines',
  60000: 'Mexico',
  70000: 'United States of America',
  80000: 'Taiwan',
  90000: 'Hong Kong',
  100000: 'Vietnam',
  110000: 'Thailand',
  120000: 'Indonesia',
};
export enum DC {
  SG = 'sg',
  // IN = 'bom',
  BR = 'br',
  // OSU = 'osu',
}

export const DC_ALIAS_WITH_WEB_APP = {
  [DC.SG]: 'SIN',
  [DC.BR]: 'SAO',
  // [DC.OSU]: 'OSU',
};

export const DC_FULL_NAME: Record<DC, string> = {
  [DC.SG]: 'Southeast Asia',
  // [DC.IN]: 'India',
  [DC.BR]: 'Latin America',
  // [DC.OSU]: 'US',
};

export const DC_DEFAULT_COUNTRY = {
  [DC.SG]: COUNTRY.HONGKONG,
  // [DC.IN]: COUNTRY.INDIA,
  [DC.BR]: COUNTRY.BRAZIL,
  // [DC.OSU]: COUNTRY.USA,
};

export const COUNTRY_DC_MAP = {
  CHINA: DC.SG,
  // INDIA: DC.IN,
  BRAZIL: DC.BR,
  SINGAPORE: DC.SG,
  MALASIA: DC.SG,
  PHILIPPINES: DC.SG,
  MEXICO: DC.BR,
  // USA: DC.OSU,
  TAIWAN: DC.SG,
  HONGKONG: DC.SG,
  VIETNAM: DC.SG,
  THAILAND: DC.SG,
  INDONESIA: DC.SG,
};

export const TOP_UP_LIMIT_DEFAULT = 100000;

export const COUNTRY_TOP_UP_LIMIT: Record<string, number> = {
  CHINA: 0,
  PHILIPPINES: TOP_UP_LIMIT_DEFAULT * 10,
  USA: 0,
  VIETNAM: TOP_UP_LIMIT_DEFAULT * 1000,
  INDONESIA: TOP_UP_LIMIT_DEFAULT * 1000,
};

export const ROUTERS = {
  HOME: '/',
  REGISTER: '/register',
  LOGIN: '/login',
  RECORDS: '/records',
  DEVELOPERS: '/developers',
  WALLET: '/wallet',
  TERMS_OF_USE: '/terms-of-use',
  NOT_FOUND: '/404',
  WEBHOOKS: '/developers/webhooks',
};

export const STORAGE_KEY = {
  TOKEN_PROD: 'token-production',
  TOKEN_SAND: 'token-sandbox',
  CITY_CURRENT: 'city-current',
  COUNTRY_CURRENT: 'contry-current',
  ENV_APP_CURRENT: 'env-app-current',
  PAGE_CURRENT: 'page-current', // 当前访问页面,用于下次登录时直接进入该页面
  LLM_AGENT: 'llm-agent',
  CURRENT_EP_ID: 'current_ep_id',
  IS_USER_LOGIN_BEFORE: 'is-user-login-before',
};

export const HEADER_PROD = {
  headers: {
    env: ENV_APP.PRODUCTION,
  },
};

export const HEADER_SAND = {
  headers: {
    env: ENV_APP.SANDBOX,
  },
};

export const MAX_RESEND_TIME = 60;
