// eslint-disable-next-line @typescript-eslint/triple-slash-reference
/**
 * The code is generated automaticlly. Please don not edit it by yourself
 * @desc 自动登录sandbox环境
 */
import requestUmi from '@/apis/request-wrapper';
import '../../api.d.ts';

export function request(params: any = {}, options: any = {}) {
  return requestUmi<any, any>('/automation-access-token', {
    method: 'post',
    data: params || {},
    ...(options || {}),
  });
}
