// eslint-disable-next-line @typescript-eslint/triple-slash-reference
/**
 * The code is generated automaticlly. Please don not edit it by yourself
 * @desc 获取用户信息
 */
import requestUmi from '@/apis/request-wrapper';
import '../../api.d.ts';

export function request(params: any = {}, options: any = {}) {
  return requestUmi<any, defs.pp.UserInfo>('/user', {
    method: 'get',
    params: params || {},
    ...(options || {}),
  });
}
