// dependencies
import { createContext, useState } from 'react';
// types
import type { FC } from 'react';
import type { UserInfo } from '@/types/context';
import type { AccountInfoWrapper } from '@/types/project';
// others
import { noop } from '@/utils/helpers';

// #region ------ types ------
interface IContextUser {
  userInfoProd: UserInfo;
  setUserInfoProd: (e: UserInfo) => void;
  accountInfoProd: AccountInfoWrapper;
  setAccountInfoProd: (e: AccountInfoWrapper) => void;
  accountInfoSand: AccountInfoWrapper;
  setAccountInfoSand: (e: AccountInfoWrapper) => void;
}
// #endregion

const initialValue = {
  userInfoProd: Object(),
  setUserInfoProd: noop,
  accountInfoProd: Object(),
  setAccountInfoProd: noop,
  accountInfoSand: Object(),
  setAccountInfoSand: noop,
};

export const ContextUser = createContext<IContextUser>(initialValue);

export const ProviderUser: FC = ({ children }) => {
  const [userInfoProd, setUserInfoProd] = useState(Object());
  const [accountInfoProd, setAccountInfoProd] = useState(Object());
  const [accountInfoSand, setAccountInfoSand] = useState(Object());

  const value = {
    userInfoProd,
    setUserInfoProd,
    accountInfoProd,
    setAccountInfoProd,
    accountInfoSand,
    setAccountInfoSand,
  };
  return <ContextUser.Provider value={value}>{children}</ContextUser.Provider>;
};
