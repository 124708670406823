import { request } from 'umi';

export default async function <Opt, Res>(
  url: string,
  options: Opt,
): Promise<[null, Res] | [any, null]> {
  try {
    const res = await request(url, options);
    return [null, res];
  } catch (e) {
    return [e, null];
  }
}
