// dependencies
import { token } from '@/apis/headers-public';
import { ERROR_FE } from '@/constant';
import { ENV_APP } from '@/constant/env';
import { sharedRegion } from '@/context';
import {
  associateUser,
  createUserSandbox as createUserSand,
} from '@/services/pp/mods/users';
import jwtDecode from 'jwt-decode';
import sensors from 'sa-sdk-javascript';
// others
import {
  DC_ALIAS_WITH_WEB_APP,
  HEADER_PROD,
  HEADER_SAND,
  STORAGE_KEY,
} from './../../constant/env';
import { getDCByCountryId } from './utils';

// #region ------ types ------
interface TokenInfo {
  user_info: {
    user_id: number;
  };
  user_fid: string;
}
// #endregion

/**
 * 缓存token
 * @param sToken token值
 * @param env 当前环境
 */
export function storageToken(sToken: string, env: ENV_APP) {
  // 1. 拼接auth请求头
  // 2. 缓存到内存
  // 3. 缓存到硬盘
  const tokenNew = `Bearer ${sToken}`;
  token[env] = tokenNew;
  const key =
    env === ENV_APP.PRODUCTION
      ? STORAGE_KEY.TOKEN_PROD
      : STORAGE_KEY.TOKEN_SAND;
  localStorage.setItem(key, tokenNew);
  return tokenNew;
}

/**
 * 自动创建SANDBOX用户
 * @param encrypted_user 加密后的用户信息
 */
export async function createUserSandbox(encrypted_user: string) {
  // 1. 注册sandbox用户
  // 2. 将sandbox用户关联到production用户
  const [errRegisterSand, resRegisterSand] = await createUserSand.request(
    { encrypted_user },
    HEADER_SAND,
  );
  if (errRegisterSand) {
    return ERROR_FE.REGISTER_SANDBOX_USER_FAIL;
  }
  const [errAsso] = await associateUser.request(
    {
      sandbox_user_id: resRegisterSand.user_id,
      sandbox_ep_id: resRegisterSand.ep_id,
    },
    HEADER_PROD,
  );
  if (errAsso) {
    return ERROR_FE.ASSOCIATE_SANDBOX_PROD_USER_FAIL;
  }
  return undefined;
}

export function clearUserInfo() {
  token.PRODUCTION = '';
  token.SANDBOX = '';
  localStorage.removeItem(STORAGE_KEY.TOKEN_PROD);
  localStorage.removeItem(STORAGE_KEY.TOKEN_SAND);
  localStorage.removeItem(STORAGE_KEY.CURRENT_EP_ID);
}
export function clearIsUserLoginBefore() {
  localStorage.removeItem(STORAGE_KEY.IS_USER_LOGIN_BEFORE);
}

export async function sensorLogin(tokenProduction: string) {
  try {
    const tokenInfo: TokenInfo = await jwtDecode(tokenProduction);
    const dataCenter =
      DC_ALIAS_WITH_WEB_APP[
        getDCByCountryId(sharedRegion.countryCurrent.countryId)
      ];
    sensors.login(`${dataCenter}-${tokenInfo.user_fid}`);
  } catch (e) {
    console.log('sensor login error');
  }
}

const getEpId = () => {
  const localEpId = localStorage.getItem(STORAGE_KEY.CURRENT_EP_ID);
  return localEpId || '';
};

export const storageEpId = (epId: string) => {
  if (epId) localStorage.setItem(STORAGE_KEY.CURRENT_EP_ID, epId);
  else localStorage.setItem(STORAGE_KEY.CURRENT_EP_ID, '');
};
export const setSkipThisPage = () => {
  const epId = getEpId();

  if (epId) localStorage.setItem(`${epId}_is_skipped_biz-verified `, 'true');
};

export const isSkipThisPage = () => {
  const epId = getEpId();

  if (epId)
    return Boolean(localStorage.getItem(`${epId}_is_skipped_biz-verified `));
  return false;
};

export const getTokenProduction = () => {
  return localStorage.getItem(STORAGE_KEY.TOKEN_PROD) || '';
};

export const setIsLoginBefore = () => {
  localStorage.setItem(STORAGE_KEY.IS_USER_LOGIN_BEFORE, 'true');
};
export const getIsLoginBefore = () => {
  return Boolean(localStorage.getItem(STORAGE_KEY.IS_USER_LOGIN_BEFORE) || '');
};
