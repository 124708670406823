// dependencies
import styled from 'styled-components';
// components
import { Spin } from 'antd';

// #region ------ styled components ------
const StyledSpin = styled(Spin)`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
`;
// #endregion

export default function () {
  return <StyledSpin size="large" tip="loading"></StyledSpin>;
}
