import { ENV_APP, STORAGE_KEY } from './../constant/env';

export const token = {
  [ENV_APP.PRODUCTION]: '',
  [ENV_APP.SANDBOX]: '',
};

export const headers = {
  hcountry: '',
  hcity: '',
  hlang: '',
  Authorization: '',
  env: ENV_APP.PRODUCTION,
  hversion: '200101',
  'x-llm-agent': sessionStorage.getItem(STORAGE_KEY.LLM_AGENT) || '',
};

(() => {
  const tokenSand = localStorage.getItem(STORAGE_KEY.TOKEN_SAND);
  const tokenProd = localStorage.getItem(STORAGE_KEY.TOKEN_PROD);
  token.SANDBOX = tokenSand || '';
  token.PRODUCTION = tokenProd || '';
})();
