// dependencies
import {
  useState,
  useEffect,
  createContext,
  useCallback,
  useContext,
} from 'react';
import { message } from 'antd';
// types
import type { FC } from 'react';
import type { RegionInfoWithCityList, CityInfo } from '@/types/context';
// others
import { headers } from '@/apis/headers-public';
import { noop } from '@/utils/helpers';
import type { ENV_APP } from '@/constant/env';
import { STORAGE_KEY, COUNTRY, ENV_DEVELOP } from '@/constant/env';
import { getNearestCountryFromGeolocation } from '@/utils/geo-helper';
import { regionGet } from '@/services/base/mods/bases';
import { getCities } from '@/services/pp/mods/bases';
import { initSensors } from '@/assets/js/utils';
import { ContextEnv } from './context-env';
import { envDevelop } from '@/utils/env';

// #region ------ types ------
interface IContextRegion {
  cityCurrent: CityInfo;
  setCityCurrent: (e: CityInfo) => void;
  countries: RegionInfoWithCityList[];
  countryCurrent: RegionInfoWithCityList;
  changeCountryCurrent: (e: RegionInfoWithCityList) => void;
  languageCurrent: string;
}

interface ISharedRegion {
  cityCurrent: CityInfo;
  countryCurrent: RegionInfoWithCityList;
  languageCurrent: string;
}
// #endregion

const initialValue = {
  cityCurrent: Object(),
  setCityCurrent: noop,
  countries: Array(),
  countryCurrent: Object(),
  changeCountryCurrent: noop,
  languageCurrent: 'en_HK',
};

export const sharedRegion: ISharedRegion = {
  cityCurrent: Object(),
  countryCurrent: Object(),
  languageCurrent: '',
};

export function sortCountriesByEnglishName(countries: defs.base.RegionInfo[]) {
  return countries
    .filter((ele) => ele.countryId !== 70000)
    .sort((a, b) => {
      const aTrans = a.translations.find((trans) => trans.id.startsWith('en'));
      const bTrans = b.translations.find((trans) => trans.id.startsWith('en'));
      const aName = aTrans ? aTrans.value : a.id;
      const bName = bTrans ? bTrans.value : b.id;
      return aName.localeCompare(bName);
    });
}

export function getCountryStored() {
  const countryStored = localStorage.getItem(STORAGE_KEY.COUNTRY_CURRENT);
  let region: RegionInfoWithCityList = Object();
  if (countryStored) {
    try {
      region = JSON.parse(countryStored);
    } catch (e) {
      localStorage.removeItem(STORAGE_KEY.COUNTRY_CURRENT);
    }
  }

  return region;
}

export function getCityStored() {
  const cityStoraged = localStorage.getItem(STORAGE_KEY.CITY_CURRENT);
  let city: CityInfo = Object();
  if (cityStoraged) {
    try {
      city = JSON.parse(cityStoraged);
    } catch (e) {
      localStorage.removeItem(STORAGE_KEY.COUNTRY_CURRENT);
    }
  }
  return city;
}

export async function initCountryCurrent(countries: RegionInfoWithCityList[]) {
  // 1. 从缓存中获取
  // 2. 通过定位获取
  // 3. 取默认香港
  let country =
    countries.find((ele) => ele.countryId === COUNTRY.HONGKONG) || countries[0];
  const countryStored = getCountryStored();
  if (countryStored.id) {
    country = countries.find((ele) => ele.id === countryStored.id) || country;
  } else {
    const nearestCountry = await getNearestCountryFromGeolocation(countries);
    if (nearestCountry) {
      country = nearestCountry;
    }
  }
  return country;
}

export function sortCitiesByAlpha(cities: CityInfo[]) {
  return cities.sort((pre, cur) =>
    (pre.CityName || '') > (cur.CityName || '') ? 1 : -1,
  );
}

export const ContextRegion = createContext<IContextRegion>(initialValue);

const getRegionConfigBaseURL = () => {
  if (envDevelop === ENV_DEVELOP.DEV) {
    return 'https://region-configuration-stg.lalamove.com?env=stg';
  }
  if (envDevelop === ENV_DEVELOP.STG) {
    return 'https://region-configuration-stg.lalamove.com?env=stg';
  }
  if (envDevelop === ENV_DEVELOP.PRE) {
    return 'https://region-configuration-pre.lalamove.com?env=pre';
  }
  return 'https://region-configuration.lalamove.com?env=prd';
};

export async function initCountries(
  times: number = 1,
): Promise<RegionInfoWithCityList[] | undefined> {
  const [err, res] = await regionGet.request(
    {},
    {
      prefix: getRegionConfigBaseURL(),
      skipErrorHandler: true,
    },
  );

  if (err || !res) {
    if (times < 3) {
      return await initCountries(times + 1);
    }
    message.error({ content: 'Network error, please try again later' });
    return undefined;
  }
  const data = sortCountriesByEnglishName(res.data);
  return data.map((ele) => ({
    ...ele,
    cityList: [],
  })) as RegionInfoWithCityList[];
}

export async function initCites(
  marketId: number,
  times: number = 1,
  envApp?: ENV_APP,
): Promise<CityInfo[] | undefined> {
  // 1. 查询城市列表
  // 2. 初始化当前城市
  //    2.1 优先以缓存的城市CODE为准
  //    2.2 否则取第1个城市作为默认城市
  const [err, res] = await getCities.request(
    {},
    {
      skipErrorHandler: true,
      headers: {
        hcountry: marketId,
        ...(envApp && { env: envApp }),
      },
    },
  );
  if (err || !res) {
    if (times < 3) {
      return initCites(marketId, times + 1, envApp);
    }
    return undefined;
  }
  const citiesSorted = sortCitiesByAlpha(res);
  return citiesSorted;
}

export async function initAllCities(
  countryList: defs.base.RegionInfo[],
  envApp?: ENV_APP,
): Promise<RegionInfoWithCityList[] | undefined> {
  const promiseList = [];
  const lengthCountries = countryList.length;
  for (let i = 0; i < lengthCountries; i += 1) {
    promiseList[i] = initCites(countryList[i].countryId, undefined, envApp);
  }

  const promiseRes = await Promise.all(promiseList);

  const countriesNew = [];
  const lengthRes = promiseRes.length;
  for (let i = 0; i < lengthRes; i += 1) {
    const cityList = promiseRes[i] || [];
    countriesNew[i] = { ...countryList[i], cityList };
  }

  return countriesNew as RegionInfoWithCityList[];
}
export const ProviderRegion: FC = ({ children }) => {
  // TODO 国家和城市要做个缓存
  const [countries, setCountries] = useState<RegionInfoWithCityList[]>(Array());
  const [countryCurrent, setCountryCurrent] = useState<RegionInfoWithCityList>(
    Object(),
  );
  const [cityCurrent, setCityCurrent] = useState(Object());
  const [languageCurrent, changeLanguageCurrent] = useState<string>(
    initialValue.languageCurrent,
  );
  const { envAppCurrent } = useContext(ContextEnv);

  const changeCityCurrent = useCallback((city: CityInfo = Object()) => {
    setCityCurrent(city);
    headers.hcity = `${city.CityID || ''}`;
    sharedRegion.cityCurrent = city;
    localStorage.setItem(STORAGE_KEY.CITY_CURRENT, JSON.stringify(city));
  }, []);

  const changeCountryCurrent = useCallback(
    (country: RegionInfoWithCityList) => {
      setCountryCurrent(country);

      const nextLanguage =
        country.languages.find(({ id }) => id.startsWith('en'))?.id || 'en_HK';
      changeLanguageCurrent(nextLanguage);

      headers.hcountry = `${country.countryId}`;
      headers.hlang = nextLanguage.toLowerCase();
      sharedRegion.countryCurrent = country;
      sharedRegion.languageCurrent = headers.hlang;

      localStorage.setItem(
        STORAGE_KEY.COUNTRY_CURRENT,
        JSON.stringify(country),
      );
    },
    [],
  );

  useEffect(() => {
    const countryStored = getCountryStored();
    const cityStored = getCityStored();

    setCountryCurrent(countryStored);
    setCityCurrent(cityStored);

    headers.hcountry = `${countryStored.countryId || ''}`;
    headers.hcity = `${cityStored.CityID || ''}`;
  }, []);

  useEffect(() => {
    // 1. 初始化国家
    // 2. 同时查询所有国家的城市
    // 3. 将城市作为国家的一个属性存放
    // 4. 更新国家列表
    // 5. 更新当前国家
    // 6. 改变当前国家时,同时改变城市列表setCities

    async function initCountriesAndCities() {
      const countryListWithoutCityList = await initCountries();
      if (!countryListWithoutCityList) {
        return;
      }
      // fix: 首次进入partnerportal, 请求cities接口, 依赖headers.hcountry
      const country = await initCountryCurrent(countryListWithoutCityList);
      changeCountryCurrent(country);

      const countryListWithCityList = await initAllCities(
        countryListWithoutCityList,
      );
      // TODO 只要一个国家的城市获取失败,整个都down掉,这有点有反科学,因为用户可能根本不会点击到这个国家
      if (!countryListWithCityList) {
        return;
      }

      setCountries(countryListWithCityList);

      const countryWithCityList = countryListWithCityList.find(
        (ele) => ele.id === country.id,
      ) as RegionInfoWithCityList;
      changeCountryCurrent(countryWithCityList);

      const { cityList } = countryWithCityList;

      let city = cityList[0];
      const cityS = getCityStored();

      if (cityS.CityID) {
        city = cityList.find((ele) => ele.CityID === cityS.CityID) || city;
      }
      changeCityCurrent(city);
      initSensors();
    }
    initCountriesAndCities();
  }, [changeCountryCurrent, changeCityCurrent, envAppCurrent]);

  const value = {
    cityCurrent,
    setCityCurrent: changeCityCurrent,
    countries,
    countryCurrent,
    changeCountryCurrent,
    languageCurrent,
    changeLanguageCurrent,
  };

  return (
    <ContextRegion.Provider value={value}>{children}</ContextRegion.Provider>
  );
};
