// types
import type { RequestOptionsInit } from 'umi-request';
// components
import { message } from 'antd';
// others
import {
  DC,
  ENV_DEVELOP,
  URL_BASE_CONFIG,
  ENV_APP,
  COUNTRY,
  ROUTERS,
} from '@/constant/env';
import { envDevelop } from '@/utils/env';
import { headers, token } from '@/apis/headers-public';
import { clearUserInfo } from '@/assets/js/users';
import { getDCByCountryId } from '@/assets/js/utils';
import { v4 as uuid } from 'uuid';

const BASE_URL = 'partnerportal-api';
const RULE_NEW: Record<string, boolean> = {
  // [COUNTRY.USA.toString()]: true,
  [COUNTRY.INDIA.toString()]: true,
};
const DOMAIN: Record<string, string> = {
  [COUNTRY.INDIA]: 'uncle-delivery.com',
};
const DOMAIN_DEFAULT = 'lalamove.com';

/**
 * @example stg -> partnerportal-api-stg
 *          pre -> partnerportal-api-pre
 *          production -> partnerportal-api
 */
export function combineEnv(url: string, envDev: ENV_DEVELOP, envApp: ENV_APP) {
  // 1. 非生产环境拼接后缀
  if (envDev !== ENV_DEVELOP.PRODUCTION) {
    return `${url}-${envDev.toLowerCase()}`;
  }
  // 2. 生产环境直接返回
  if (envApp === ENV_APP.SANDBOX) {
    return `${url}-pre`;
  }
  return url;
}
/**
 * @example stg -> sg-partnerportal-api-sg
 *          pre -> sg-partnerportal-api-pre
 *          production,10000 -> in-partnerportal-api
 *          production,70000 -> partnerportal-api.osu
 */
export function combineDC(
  url: string,
  hcountry: string,
  envDev: ENV_DEVELOP,
  envApp: ENV_APP,
) {
  // 开发是非生产环境/或App是sandbox   直接返回SG
  // 生产环境根据美国进行判断
  if (envDev !== ENV_DEVELOP.PRODUCTION || envApp === ENV_APP.SANDBOX) {
    return `${DC.SG}-${url}`;
  }
  const iHcountry = Number.parseInt(hcountry, 10);
  const dc = getDCByCountryId(iHcountry);

  if (RULE_NEW[hcountry]) {
    return `${url}.${dc}`;
  }
  const urlNew = `${dc}-${url}`;
  return urlNew;
}

/**
 * 获取域名
 * @param hcountry 国家ID
 * @param envDev 当前环境
 */
export function getDomain(
  hcountry: string,
  envDev: ENV_DEVELOP,
  envApp: ENV_APP,
) {
  // 开发是非生产环境/或App是sandbox 返回默认域名
  // 生产环境根据配置返回
  if (envDev !== ENV_DEVELOP.PRODUCTION || envApp === ENV_APP.SANDBOX) {
    return DOMAIN_DEFAULT;
  }
  return DOMAIN[hcountry] || DOMAIN_DEFAULT;
}

/**
 * 拼接请求URL
 * @param pathOrigin 请求的path eg: /cities
 * @param prefixOrigin 请求时的前缀 eg: /api-dev
 * @param hcountry 国家id
 * @param envDev 当前环境
 * @returns
 */
export const getRequestUrl = (
  pathOrigin: string,
  prefixOrigin: string,
  hcountry: string,
  envDev: ENV_DEVELOP,
  envApp: ENV_APP,
) => {
  // 如果http开头,返回空
  // 如果本身有设置,直接返回
  // 拼接环境
  // 拼接数据中心
  if (/http/.test(pathOrigin)) {
    return '';
  }
  if (prefixOrigin) {
    return `${prefixOrigin}${pathOrigin}`;
  }
  let url = combineEnv(BASE_URL, envDev, envApp);
  url = combineDC(url, hcountry, envDev, envApp);
  const domain = getDomain(hcountry, envDev, envApp);
  return `https://${url}.${domain}${pathOrigin}`;
};

export function getCountryIdByRequestPath(
  requestPath: string,
  countryIdInRequestHeader: string,
  countryIdPublic: string,
) {
  // /cities这个接口是根据options.headers?.hcountry的国家ID查询城市id
  // 避免在SG依赖其他DC的cities接口,cities的url拼接仅以headers.hcountry为准
  if (requestPath === '/cities') {
    return countryIdPublic;
  }
  return countryIdInRequestHeader || countryIdPublic;
}

export const processUrl = (url: string, options: RequestOptionsInit) => {
  // headers.hcountry: 用户选择的国家ID,全局惟一
  // options.headers?.hcountry: 当前接口自定义的国家ID
  const hcountry = getCountryIdByRequestPath(
    options.url,
    // @ts-ignore
    options.headers?.hcountry,
    headers.hcountry,
  );

  const urlNew = getRequestUrl(
    options.url,
    options.prefix || '',
    hcountry,
    envDevelop,
    // @ts-ignore
    options.headers?.env || headers.env,
  );

  return {
    url: urlNew,
    options,
  };
};

export function addRequestHeaderField(
  url: string,
  options: RequestOptionsInit,
) {
  // 公共接口不用传额外的header头
  if (options.prefix === URL_BASE_CONFIG) {
    return {
      url,
      options,
    };
  }
  const headersNew = {
    ...headers,
    ...options.headers,
    su: uuid().replace(/-/g, ''),
  };

  headersNew.Authorization =
    headersNew.env === ENV_APP.PRODUCTION ? token.PRODUCTION : token.SANDBOX;

  return {
    url,
    options: {
      ...options,
      headers: headersNew,
    },
  };
}

export function processRESTPath(url: string, options: RequestOptionsInit) {
  // 1. 取出path中参数部分
  // 2. 判断当前入参中是否包含对应的参数名
  // 3. 用参数值替换path中的名称
  // 4. 删除掉入参中该参数 如: /orders/{orderId} -> /orders/12345
  const pathParamReg = /\/\{(.*)\}/;
  const [, pathParam] = url.match(pathParamReg) || [];

  let urlNew = url;
  if (pathParam) {
    const { params, data } = options;
    // @ts-ignore
    const pathParamValue = params[pathParam] || data[pathParam];
    if (pathParamValue) {
      if (params) {
        // @ts-ignore
        delete params[pathParam];
      }
      if (data) {
        delete data[pathParam];
      }
    }
    urlNew = urlNew.replace(pathParamReg, () => {
      return pathParamValue ? `/${pathParamValue}` : '';
    });
  }
  return {
    url: urlNew,
    options,
  };
}

export function handleResponseStatus(res: Response) {
  switch (res.status) {
    case 401:
      clearUserInfo();
      window.location.replace(ROUTERS.LOGIN);
      break;
    case 500:
      message.error('Unkown error');
      break;
    default:
      break;
  }
  return res;
}
