export const CONTACT_METHOD = [
  {
    value: 'email',
    label: 'Email',
  },
  {
    value: 'phone',
    label: 'Phone',
  },
];

export enum ERROR_FE {
  REGISTER_SANDBOX_USER_FAIL = 10010,
  ASSOCIATE_SANDBOX_PROD_USER_FAIL = 10011,
  LOGIN_SANDBOX_FAIL = 10012,
}

export const EMAIL_SUPPORT = 'partner.support@lalamove.com';

export const MSG_COMMON_ERR = 'Something went to wrong';
