// dependencies
import { ThemeProvider } from 'styled-components';
// types
import type { DefaultTheme } from 'styled-components';
import type { FC } from 'react';

class Theme {
  // 颜色
  cTheme = '#f16622';
  cGray3 = '#333';
  cGray6 = '#666';
  cGray9 = '#999';
  cGrayc = '#ccc';
  cDanger = '#D8534F';
  // 背景色
  bgcTheme = '#F1F1F1';
  // 边框
  bdTheme = `1px solid ${this.cTheme}`;
  // font
  fzXS = '10px';
  fzSM = '12px';
  fzNM = '14px';
  fzLG = '16px';
  fzXL = '18px';
  fzXXL = '24px';
  // size
  sXS = '8px';
  sSM = '12px';
  sMD = '16px';
  sLG = '20px';
  sXL = '24px';
}
const THEME: DefaultTheme = new Theme();

export const ProviderTheme: FC = function ({ children }) {
  return <ThemeProvider theme={THEME}>{children}</ThemeProvider>;
};
