import { ENV_DEVELOP } from '@/constant/env';

export function initEnv(origin: string, search: string = '') {
  if (process.env.NODE_ENV === 'development') {
    return ENV_DEVELOP.DEV;
  }

  // for testing: can request production endpoints in stg/pre env
  if (search.includes('isProduction=true')) {
    return ENV_DEVELOP.PRODUCTION;
  }

  if (origin.includes('dev.')) {
    return ENV_DEVELOP.DEV;
  }

  if (origin.includes('stg.')) {
    return ENV_DEVELOP.STG;
  }

  if (origin.includes('pre.')) {
    return ENV_DEVELOP.PRE;
  }

  return ENV_DEVELOP.PRODUCTION;
}

export const envDevelop = initEnv(
  window.location.origin,
  window.location.search,
);
