import { sensorLogin } from '@/assets/js/users';
// dependencies
import sensors from 'sa-sdk-javascript';
import moment from 'moment-timezone';
import { message } from 'antd';
// types
import type { RegionInfoWithCityList, CityInfo } from '@/types/context';
import type { DropDownItem } from '@/types/lalamove-ui';
// other
import { COUNTRY, COUNTRY_DC_MAP } from '@/constant/env';
import { sharedRegion } from '@/context';
import { DC_ALIAS_WITH_WEB_APP, ENV_DEVELOP } from './../../constant/env';
import { envDevelop } from './../../utils/env';
import { token } from '@/apis/headers-public';

// #region ------ types ------
type TCountryName = keyof typeof COUNTRY_DC_MAP;
// #endregion

const FORMAT = 'YYYY-MM-DD';

export function parseCountryBase(
  country: RegionInfoWithCityList,
  appendAreaCode: boolean,
  needCityList: boolean,
) {
  const { id, areaCode, defaultLanguage, translations, cityList } = country;
  let name: string = id;
  if (translations && defaultLanguage) {
    const translation = translations.find((lang) => lang.appValue === 'en');
    name = translation ? translation.value : translations[0].value;
  }
  const appendStr = appendAreaCode ? ` (${areaCode})` : '';
  const label = `${name}${appendStr}`;

  const res: DropDownItem = {
    label,
    value: id,
  };

  if (needCityList && cityList) {
    res.options = cityList.map(parseCity);
  }
  return res;
}

export function parseCountryWithArea(
  country: RegionInfoWithCityList,
  needCityList: boolean = false,
) {
  return parseCountryBase(country, true, needCityList);
}

export function parseCountryWithoutArea(
  country: RegionInfoWithCityList,
  needCityList: boolean = false,
) {
  return parseCountryBase(country, false, needCityList);
}

export function parseCity(city: CityInfo) {
  return {
    label: city.CityName || '',
    value: `${city.CityID}`,
  };
}

export function getRangeDate(range = 30, timeZone = 'UTC') {
  return [
    moment().tz(timeZone).subtract(range, 'day').format(FORMAT),
    moment().tz(timeZone).format(FORMAT),
  ];
}

export function getCountryIdFromCityId(cityId: number) {
  return Math.floor(cityId / 10000) * 10000;
}

export function getErrorMsg(err: any) {
  return err?.data?.detail || 'Something went to wrong';
}

export function showErrorMessage(err?: string) {
  message.error(err || 'Something went to wrong, please try again later');
}

export function getDCByCountryId(countryIdCurrent: number) {
  const countryCur = COUNTRY[countryIdCurrent] as TCountryName;
  const dcCurrent = COUNTRY_DC_MAP[countryCur];
  return dcCurrent;
}

export function initSensors() {
  sensors.init({
    server_url:
      envDevelop === ENV_DEVELOP.PRODUCTION
        ? 'https://uba.huolalamove.net/sa?project=default'
        : 'https://uba-dev.huolala.cn/sa?project=default',
    show_log: envDevelop !== ENV_DEVELOP.PRODUCTION,
    send_type: 'beacon',
  });

  sensors.registerPage({
    global_source: 'partner_portal',
    trigger_time: () => new Date().toISOString(),
    city: () => sharedRegion.cityCurrent.CityCode,
    language: () => sharedRegion.languageCurrent,
    data_center: () =>
      DC_ALIAS_WITH_WEB_APP[
        getDCByCountryId(sharedRegion.countryCurrent.countryId)
      ],
    app_version: '1.0.0',
    country: () => sharedRegion.countryCurrent.id,
    env: envDevelop,
  });

  sensors.quick('autoTrack');

  sensorLogin(token.PRODUCTION);
}

export const EVENT_LOGIN = 'logged_in_pp';

export const HELP_LINK = 'https://developers.lalamove.com/#getting-on-board';

export const getTopUpUrl = () =>
  ({
    [ENV_DEVELOP.DEV]: 'https://web-stg.lalamove.com/#modal=topup',
    [ENV_DEVELOP.STG]: 'https://web-stg.lalamove.com/#modal=topup',
    [ENV_DEVELOP.PRE]: 'https://web-pre.lalamove.com/#modal=topup',
    [ENV_DEVELOP.PRODUCTION]: 'https://web.lalamove.com/#modal=topup',
  }[envDevelop]);

export const getPostpaidUrl = () =>
  ({
    [ENV_DEVELOP.DEV]: 'https://web-stg.lalamove.com/wallet/balance-history',
    [ENV_DEVELOP.STG]: 'https://web-stg.lalamove.com/wallet/balance-history',
    [ENV_DEVELOP.PRE]: 'https://web-pre.lalamove.com/wallet/balance-history',
    [ENV_DEVELOP.PRODUCTION]: 'https://web.lalamove.com/wallet/balance-history',
  }[envDevelop]);
