// dependencies
import type { RequestConfig } from 'umi';
import { PageLoading } from '@ant-design/pro-layout';
import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';
// others
import { errorAdaptor } from '@/apis/handler';
import 'utils/i18n';
import { envDevelop } from './utils/env';
import {
  addRequestHeaderField,
  handleResponseStatus,
  processRESTPath,
  processUrl,
} from '@/apis/interceptor';
import { ENV_DEVELOP } from './constant/env';

Sentry.init({
  dsn: REACT_APP_SENTRY_URL,
  integrations: [new Integrations.BrowserTracing()],
  // TODO 在umi中怎么通过环境变量拿到version
  release: `partnerportal-web@1.0.0`,
  environment: envDevelop,
  tracesSampleRate: 1.0,
});

// @see [umijs/plugin-request]{@link https://umijs.org/zh-CN/plugins/plugin-request}
export const request: RequestConfig = {
  prefix:
    envDevelop === ENV_DEVELOP.DEV || process.env.NODE_ENV === 'development'
      ? '/api-dev'
      : '',
  credentials: 'same-origin',
  timeout: 10000,
  requestInterceptors: [processUrl, addRequestHeaderField, processRESTPath],
  responseInterceptors: [handleResponseStatus],
  errorConfig: {
    adaptor: errorAdaptor(),
  },
};

// 页面初始化时的 loading
export const initialStateConfig = {
  loading: <PageLoading />,
};
