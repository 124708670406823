// dependencies
import Dinero from 'dinero.js';
// types
import type { Currency } from 'dinero.js';
import type { CaptchaRefType } from '@/types/types';

export const noop = () => {};

/**
 * 转换货币金额
 * @see [currency描述]{@link https://wiki.huolala.work/display/LP/Country-level+Order+Price+Decimal}
 * @param amount 金额(分)
 * @param currency 货币信息(region接口返回)
 * @returns 转换后的金额
 */
export const parseCurrency = (
  amount: number,
  currency: defs.base.CurrencyInfo,
  prefixSymbol = true,
) => {
  const { code, rate, precision_len, symbol } = currency;
  let precision = '0'.repeat(precision_len);
  if (precision) {
    precision = `.${precision}`;
  }
  const result = Dinero({
    amount,
    currency: code as Currency,
    precision: Math.log10(rate),
  }).toFormat(`0,0${precision}`);
  return `${prefixSymbol ? symbol : ''}${result}`;
};

export const getCaptchaToken = async (
  captchaRef: React.RefObject<CaptchaRefType>,
  updateLoading: (loading: boolean) => void = () => {},
) => {
  updateLoading(true);
  const { captchaToken } = (await captchaRef.current?.execute()) || {
    captchaToken: '',
  };
  updateLoading(false);
  return captchaToken;
};
