import type { RegionInfoWithCityList } from '@/types/context';

// #region ------ types ------
type IGetCurrentPosition = () => Promise<any>;
class LatLng {
  constructor(public lat: number, public lng: number) {}
}

type IGetNearestCountry = (
  counties: RegionInfoWithCityList[],
  locations: LatLng,
) => RegionInfoWithCityList | null;
// #endretion

// https://en.wikipedia.org/wiki/Haversine_formula
const coordDistance = (p1: LatLng, p2: LatLng) => {
  const R = 6371e3; // earth radius in metres
  const toRad = Math.PI / 180;
  const lat1 = p1.lat * toRad;
  const lat2 = p2.lat * toRad;
  const latDiff = (p2.lat - p1.lat) * toRad;
  const lngDiff = (p2.lng - p1.lng) * toRad;

  const a =
    Math.sin(latDiff / 2) * Math.sin(latDiff / 2) +
    Math.cos(lat1) *
      Math.cos(lat2) *
      Math.sin(lngDiff / 2) *
      Math.sin(lngDiff / 2);
  const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));

  const d = R * c;
  return d;
};

const getCurrentPosition: IGetCurrentPosition = () =>
  new Promise((resolve, reject) => {
    navigator.geolocation.getCurrentPosition(resolve, reject, {
      timeout: 3000,
    });
  });

const getNearestCountry: IGetNearestCountry = (countries, currentLatLng) => {
  let nearestDistance = Number.MAX_SAFE_INTEGER;
  if (!countries.length) {
    return null;
  }
  let nearestLatLng = countries[0];

  for (let i = 0; i < countries.length; i += 1) {
    let currentDistance = Number.MAX_SAFE_INTEGER;
    if (countries[i].lat && countries[i].lng) {
      currentDistance = coordDistance(
        currentLatLng,
        new LatLng(countries[i].lat, countries[i].lng),
      );
    }
    if (currentDistance < nearestDistance) {
      nearestDistance = currentDistance;
      nearestLatLng = countries[i];
    }
  }

  return nearestLatLng;
};

export const getNearestCountryFromGeolocation = async (
  countries: RegionInfoWithCityList[],
) => {
  try {
    const { coords } = await getCurrentPosition();
    const latLng = new LatLng(coords.latitude, coords.longitude);
    return getNearestCountry(countries, latLng);
  } catch (err) {
    console.log('getNearestCountryFromGeolocation error: ', err); // eslint-disable-line no-console
    return null;
  }
};
