// eslint-disable-next-line @typescript-eslint/triple-slash-reference
/**
 * The code is generated automaticlly. Please don not edit it by yourself
 * @desc 注册C端用户
 */
import requestUmi from '@/apis/request-wrapper';
import '../../api.d.ts';

export function request(params: any = {}, options: any = {}) {
  return requestUmi<any, any>('/corporate-user', {
    method: 'post',
    data: params || {},
    ...(options || {}),
  });
}
