// dependencies
import { createContext, useState } from 'react';
// types
import type { FC } from 'react';
// others
import { ENV_APP, STORAGE_KEY } from '@/constant/env';
import { noop } from '@/utils/helpers';
import {
  headers,
  // setConfigGlobal,
  token,
} from '@/apis/headers-public';

// #region ------ types ------
interface IEnvContextType {
  isProd: boolean;
  envAppCurrent: ENV_APP;
  setEnvApp: (e: ENV_APP) => void;
}
// #endregion

const initalValue = {
  isProd: true,
  envAppCurrent: ENV_APP.PRODUCTION,
  setEnvApp: noop,
};

export function initEnv() {
  const storagedEnv =
    (localStorage.getItem(STORAGE_KEY.ENV_APP_CURRENT) as ENV_APP) ||
    ENV_APP.PRODUCTION;

  headers.env = storagedEnv;
  return storagedEnv;
}

export function setEnvAppOutOfReact(envApp: ENV_APP) {
  headers.env = envApp;
  localStorage.setItem(STORAGE_KEY.ENV_APP_CURRENT, envApp);
}

export const ContextEnv = createContext<IEnvContextType>(initalValue);

export const ProviderEnv: FC = (props) => {
  const [envAppCurrent, setEnvAppCurrent] = useState(initEnv());

  const setEnvApp = (envApp: ENV_APP) => {
    headers.Authorization = token[envApp];
    setEnvAppCurrent(envApp);
    setEnvAppOutOfReact(envApp);
  };

  const value = {
    isProd: envAppCurrent === ENV_APP.PRODUCTION,
    envAppCurrent,
    setEnvApp,
  };
  return (
    <ContextEnv.Provider value={value}>{props.children}</ContextEnv.Provider>
  );
};
export const clearEnvApp = () => {
  localStorage.removeItem(STORAGE_KEY.ENV_APP_CURRENT);
};
